/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';


body {
  font-family: Montserrat, sans-serif;
}

html {
  background-color: var(--wr-color-dark-blue);
}

// Global settings for rtl mode
.rtl {
  .arabic-transform {
    transform: scale(-1, 1);
  }
}

/* Ionic Forms */
ion-input.wr-input {
  --background: var(--wr-color-grey-blue);
  --color: var(--wr-color-white);
  --placeholder-color: var(--wr-color-white);
  --highlight-background-focused: var(--wr-color-white);
  --placeholder-opacity: 0.5;
  --border-width: 0px;
  --padding-start: 15px;
  --border-radius: 10px;
}

input.wr-input {
  background: var(--wr-color-grey-blue);
  color: var(--wr-color-white);
  border-width: 0;
  padding: 0 15px;
  height: 55px;
  border-radius: 8px;
  width: 100%;
  font-size: var(--wr-size-14);

  &:focus {
    outline: none;
  }

  &--filled {
    background-color: white;
    color: var(--wr-color-dark);
  }

  &--error {
    border: 3px solid var(--wr-color-pink);
  }

  &::placeholder {
    color: var(--wr-color-white);
    opacity: 0.5;
    font-size: var(--wr-size-14);
  }
}

ion-checkbox {
  --size: 25px;
  --border-color-checked: transparent;
  --border-color: var(--wr-color-white);
  --border-radius: 3px;
}

.select-wrapper .select-text {
  --color: var(--wr-color-black) !important;

  color: var(--wr-color-black) !important;
}

/* Ionic Buttons */
app-primary-button ion-button {
  font-size: var(--wr-size-18);
  font-weight: 500;
}

// Modals
ion-modal {
  --background: none;

  &.native-app-installation-prompt {
    --height: auto;

    margin-left: auto;
    margin-right: auto;
    margin-top: 12px;
    align-items: start;
    display: table;
  }

  // Allow absolute position going out of modal
  &.language-selection {
    &::part(content) {
      overflow: initial;
      background: none;
    }

    &::part(backdrop) {
      cursor: default;
    }
  }

  &.activity-modal {
    padding-left: 10%;
    padding-right: 10%;
  }

  &.mobile-age-filter,
  &.mobile-select-category,
  &.mobile-profile-select,
  &.mobile-language-filter,
  &.mobile-profile-shortcut {
    --height: auto !important;
    --max-height: 100% !important;

    &::part(content) {
      border-radius: 20px 20px 0 0 !important;
      overflow: auto;
      max-height: 100%;
    }

    &::part(handle) {
      background-color: var(--wr-color-medium-grey);
      margin-top: 16px;
      width: 32px;
    }
  }

  &.reader-image-viewer {
    --height: auto;
  }

  &.web-app-update {
    z-index: 20003 !important; // Ionic default modal has z-index 20002
  }

  &.wr-modal {
    --height: auto;
    --max-height: 100%;

    @media only screen and (width <= 767px) {
      align-items: start;
    }

    &::part(content) {
      border-radius: 20px;
      width: 430px;
    }

    &.not-fullscreen {
      @media only screen and (width <= 767px) {
        padding-top: 20%;
        align-items: start;

        &::part(content) {
          width: calc(100% - 20px) !important;
        }
      }
    }

    &:not(.not-fullscreen) {
      @media only screen and (width <= 767px) {
        --height: 100%;

        &::part(content) {
          border-radius: 0;
          width: 100%;
        }
      }
    }
  }

  &.authentication,
  &.moya-onboarding {
    overflow: auto;

    &::part(content) {
      width: 420px;
    }

    &.fullwidth::part(content) {
      width: auto;
    }
  }

  &.language-selection,
  &.desktop-age-filter,
  &.desktop-select-category,
  &.desktop-profile-select,
  &.desktop-language-filter,
  &.desktop-profile-shortcut {
    &::part(content) {
      width: auto;
    }
  }

  &.desktop-select-category,
  &.desktop-profile-shortcut,
  &.desktop-profile-select {
    &::part(content) {
      min-width: 380px;
    }
  }

  &.login-create-account {
    --width: 420px !important;
    --height: auto;
  }
}

// Loading controller
ion-loading {
  --background: var(--wr-color-dark-blue);
  --spinner-color: var(--wr-color-white);
}

// Toasts
ion-toast {
  --border-radius: 10px;

  font-weight: 700;

  &.toast-informative {
    --background: var(--wr-color-primary-teal);
  }

  &.toast-positive {
    --background: var(--wr-color-dark-green);
  }

  &.toast-negative {
    --background: var(--wr-color-orange);
  }

  &::part(icon) {
    width: 25px;
  }

  &::part(message) {
    color: var(--wr-color-white);
  }

  &::part(button) {
    color: var(--wr-color-white) !important;
    font-weight: 700;
    font-size: var(--wr-size-12);
  }

  .toast-content {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &.desktop {
    --max-width: var(--wr-desktop-max-width);

    margin-top: 90px;
  }

  &.mobile {
    margin-top: 63px;
  }

  &.no-header {
    margin-top: 0;
  }
}

// Desktop menu
ion-menu {
  --background: var(--wr-color-dark-blue);
  --width: 50%;
}

// App content
ion-content {
  padding-bottom: 15px;

  --background: var(--wr-color-dark-blue);
  --margin-top: var(--safe-area) !important;
}

// Max width of the page / header etc
.app-content-max-width {
  max-width: var(--wr-desktop-max-width);
  margin-left: auto;
  margin-right: auto;
  padding-top: 35px;

  @media only screen and (width <= 1100px) {
    max-width: 100%;
    padding: 20px 17px;
  }
}

// Ion inputs
ion-input.search {
  --background: var(--wr-color-white);
  --color: var(--wr-color-black);
  --height: 40px;
  --placeholder-color: var(--wr-color-light-grey-text);
  --padding-start: 10px;
  --padding-end: 10px;

  font-weight: 500;
  font-size: var(--wr-size-12);

  --border-radius: 40px;
  --highlight-color-focused: none;
  --highlight-color-valid: none;
  --highlight-color-invalid: none;

  &.desktop-search-not-active {
    transition: width 0.2s ease-in-out;
    width: 310px;
  }

  &.desktop-search-active {
    transition: width 0.2s ease-in-out;
    width: 580px;
  }
}

ion-input:not(.search) {
  margin-bottom: 30px;
  text-align: left;

  &:dir(rtl) {
    text-align: right;
  }

  --background: var(--wr-color-grey-blue) !important;
  --color: var(--wr-color-white) !important;

  color: var(--wr-color-white) !important;

  --placeholder-color: var(--wr-color-white) !important;
  --placeholder-opacity: 1 !important;

  font-size: var(--wr-size-14) !important;
  font-weight: 500;

  --highlight-color-focused: none !important;
  --highlight-color-valid: none !important;
  --highlight-color-invalid: none !important;
  --padding-bottom: 16px !important;
  --padding-end: 11px !important;
  --padding-start: 11px !important;
  --padding-top: 16px !important;
  --border-radius: 6px !important;

  border-radius: 8px;
  border: 2px solid var(--wr-color-grey-blue);

  img {
    cursor: pointer;
  }

  &.white {
    --background: var(--wr-color-white) !important;
    --color: var(--wr-color-dark) !important;

    color: var(--wr-color-dark) !important;
  }

  &.error {
    border: 2px solid var(--wr-color-pink);
    margin-bottom: 10px;
  }
}

